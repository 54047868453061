import React, { useState, useEffect } from "react";
import Layout from "@components/layout";
import FormsParts from "@components/forms-fxbo";
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby";

import listSVG1 from '@images/icon_list1.svg';

// 関数呼び出し
import { useLocation } from "@reach/router"
import { SetCookie } from "@components/setCookie"; // クッキー処理 ※IBリンクを発行してるページに設置

// 言語用設定用ファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/register.json";
import langJa from "@content/locales/ja/register.json";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Register = ( props ) => {

  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // Seo情報設定
  const pathName = 'register'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );
  
  // 言語別URL先頭設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';

  // キャンペーンデータ取得
  const data = useStaticQuery(graphql`
  query postRegisterQuery {
    jaLatestCampaign: allWpCampaign(
      filter: {campaignCategory: {nodes: {elemMatch: {slug: {eq: "depositcampaign"}}}}, campaignTag: {nodes: {elemMatch: {slug: {eq: "ja"}}}}}
      limit: 1
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          campaignCategory {
            nodes {
              slug
            }
          }
          campaignTag {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    enLatestCampaign: allWpCampaign(
      filter: {campaignCategory: {nodes: {elemMatch: {slug: {eq: "depositcampaign"}}}}, campaignTag: {nodes: {elemMatch: {slug: {eq: "en"}}}}}
      limit: 1
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          campaignCategory {
            nodes {
              slug
            }
          }
          campaignTag {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
  `
  )

  // 記事生成
  const campaignData = data[`${setLang}LatestCampaign`].edges[0].node;
  const containsFin = campaignData.campaignTag.nodes.some(node => node.slug === "fin");

  // クッキー処理
  const location = useLocation()
  let cookieArray = SetCookie( location )
  let lid, pid;
  if ( cookieArray !== undefined ){
    lid = cookieArray.lidID;
    pid = cookieArray.pidID;
  }
  const [count, setCount] = useState(0); // 再レンダリング処理
  useEffect(() => {
    setTimeout(() => {
      setCount(count + 1);
    }, 1); // 1/1000秒後
  }, []);

  return (
<>
  {seoTag}
  <Layout>
    <div className="Register" langType={setLang}>
      <div className="flex flex-col-reverse max-w-5xl w-full m-auto md:pt-10 md:flex-row">
        <div className="bg-gray-600 md:bg-white mx-auto px-8 pt-3 pb-8 md:py-5 md:w-11/12 md:mx-8 md:h-[600px] hidden md:block ">
          <div className="text-left ">
            <p className="font-bold mb-8 tracking-widest">{postLang["rgst-h2"]}</p>
            <div className="max-w-[400px] text-[#067CFA] w-full md:mr-10 relative mb-2"><img src={listSVG1} className="absolute" alt="" /><span className="ml-8 font-semibold text-xl">{postLang["rgst-h2-txt1"]}</span></div>
            <p className="mb-6 ">{postLang["rgst-p"]}</p>
            <div className="max-w-[400px] text-[#067CFA] w-full md:mr-10 relative mb-2"><img src={listSVG1} className="absolute" alt="" /><span className="ml-8 font-semibold text-xl">{postLang["rgst-h2-txt2"]}</span></div>
            <p className="mb-6">{postLang["rgst-p2"]}</p>
            <div className="max-w-[400px] text-[#067CFA] w-full md:mr-10 relative mb-2"><img src={listSVG1} className="absolute" alt="" /><span className="ml-8 font-semibold text-xl">{postLang["rgst-h2-txt3"]}</span></div>
            <p className="mb-6">{postLang["rgst-p3"]}</p>
            <div className="max-w-[400px] text-[#067CFA] w-full  md:mr-10 relative mb-2"><img src={listSVG1} className="absolute" alt="" /><span className="ml-8 font-semibold text-xl">{postLang["rgst-h2-txt4"]}</span></div>
            <p className="mb-9 ">{postLang["rgst-p4"]}</p>
            
          </div>
          

   
        </div>
        <div className="w-full px-8 md:ml-8">
          <h1 className="text-[30px] pt-10 xs:text-[35px] mb-10 md:pt-6">{postLang["rgst-h1"]}</h1>
          <FormsParts langType={setLang} pid={pid} lid={lid}/>
        </div>
      </div>
    </div>
  </Layout>
  </>
  )
}
export default Register

